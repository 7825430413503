<template>
  <div>
    <section class="lg">
      <v-container grid-list-xs fluid>
        <v-layout row wrap justify-center>
          <v-flex xs12 sm12 md6 lg6 pa-2>
            <h1 class="display-2 b text-center my-5 primaryDark--text">
              Services
            </h1>
            <p class="body-1 text-center">
              We are working hard to provide an exceptionally best coffee
              product to our customers and provide unique value to the world.
            </p>
          </v-flex>
        </v-layout>
      </v-container>
    </section>
    <v-container grid-list-xs fluid>
      <v-layout row wrap justify-center>
        <v-flex xs12> </v-flex>
        <v-flex xs12 sm12 md4 lg4 pa-2 v-for="item in items" :key="item.title">
          <v-card height="100%" flat>
            <v-img
              :src="item.img"
              width="100%"
              height="100%"
              max-height="200px"
              lazy-src="https://picsum.photos/id/11/10/6"
            >
            </v-img>
            <v-card-text>
              <h1 class="display-1 b">
                {{ item.title }}
              </h1>
              <p class="body-2">{{ item.desc }}</p>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          img: "https://oromianeconomist.files.wordpress.com/2015/07/oromia-coffee-buna-oromia-arabica-coffee.png?w=528&h=357",
          title: "Orgin Identification",
          desc: `Identifying the right species and origins of raw coffee is 
          our primary priority to get the best valuable coffee for our customers. We are continously 
          working to satisfy our customers feeling by providing the organic coffee direcly from its origin.`,
        },
        {
          img: "https://218zzz2u9z8k37r9ob41kso0-wpengine.netdna-ssl.com/wp-content/uploads/2018/09/Custom-Coffee-Roaster-2.jpg",
          title: "Coffee Roasting",
          desc: `We invented our own special techniques of coffee roasting formula to keep our 
          coffee quality and taste consistent for ever. Our roasting experts are working their
          best to elevate our customers satisfactions. We also roast based on customers requests
          for different customization.
          `,
        },
        {
          img: "https://i.pinimg.com/736x/24/72/dd/2472dda22b62b4c16486e29e4791b52f.jpg",
          title: "Coffee Grinding",
          desc: `Like coffee roasting our grinding techniques are unique. We grind in way 
          that makes our coffee excetionally tasty and rich with unque flavor. Based on our customers
        request we also grind in a costomized way.`,
        },
        {
          img: require("../../assets/product/p4.png"),
          title: "Packing",
          desc: `Our processed Coffee is packed with a high quality Almunium coated material 
          that persists the coffee's flavor, tastes, and quality for more two years.`,
        },
        {
          img: "https://en.pimg.jp/068/197/243/1/68197243.jpg",
          title: "Delivery",
          desc: `Our delivery will differ for local and international buyers. For 
          local customers the delivery will be free if order is more than 5KG Coffee. For
          International buyers we ship  for free if the order is more than 1000KG.`,
        },
        {
          img: "https://pbs.twimg.com/profile_images/485115092925554688/Jq07YCyX.jpeg",
          title: "Coffee Shop",
          desc: `We have an eceptionally suitable Coffee Cafe where our customers taste
          our delicious coffee. Our working environment, customers service, our coffee, Machiato
          and a snack for free will make your day bright.`,
        },
      ],
    };
  },
};
</script>